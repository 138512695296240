<template>
    <div
        class="project_box"
        ref="project_box"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="
            loading ||
            this.where.current >= this.pages ||
            (!autoLoad && this.where.current >= 1)
        "
        :infinite-scroll-distance="0"
        :infinite-scroll-immediate="false"
    >
        <Waterfall
            :list="list"
            :width="item_width"
            :gutter="item_gutter"
            :gridWidth="parent_width"
            :hasAroundGutter="false"
            :breakpoints="{
                1900: {
                    //当屏幕宽度小于等于1900
                    rowPerView: 7,
                },
                1440: {
                    //当屏幕宽度小于等于1440
                    rowPerView: 5,
                },
                900: {
                    //当屏幕宽度小于等于800
                    rowPerView: 4,
                },
            }"
            ref="waterfall"
        >
            <template slot="item" slot-scope="props">
                <div class="card" v-if="props.item.isCreate">
                    <div
                        class="create_box"
                        :style="{ height: item_width * 1.4 + 'px' }"
                    >
                        <div class="txt_box">
                            <div class="txt_h">视频智能混剪</div>
                            <div>点击开始视频创作吧~</div>
                        </div>
                        <div class="create_btn">创建工程</div>
                    </div>
                </div>
                <div class="card" v-else>
                    <div
                        class="project_item"
                        :style="{
                            height: props.item.height + 'px',
                            backgroundImage: `url(${props.item.coverUrl})`,
                        }"
                    >
                        <div class="project_info">
                            <div class="btns">
                                <el-button
                                    type="primary"
                                    size="mini"
                                    @click="openWork(props.item.id)"
                                    >进入工程</el-button
                                >
                                <el-button
                                    type="danger"
                                    size="mini"
                                    plain
                                    @click="delProje(props.item.id)"
                                    >删除工程</el-button
                                >
                            </div>
                            <div class="info">
                                <div class="title" @dblclick="reset_name">
                                    {{ props.item.title }}
                                </div>
                                <div class="date">
                                    {{
                                        moment(props.item.createTime).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Waterfall>
        <div class="project_tis">
            <div v-if="this.where.current >= this.pages">
                <i class="el-icon-warning"></i> 已经没有更多了
            </div>
            <div v-if="loading"><i class="el-icon-loading"></i> 正在加载</div>
        </div>
    </div>
</template>

<script>
import { LazyImg, Waterfall } from "vue-waterfall-plugin";
import { getProje, delProje, updata_project } from "@/api/engi.js";
import moment from "moment";
import "vue-waterfall-plugin/dist/style.css";
export default {
    name: "VideoVue22Test",
    props: ["autoLoad"],
    watch: {
        items(data) {
            this.list = [...this.list, ...data];
        },

        async parent_width(new_width) {
            await this.setWaterfall(new_width);

            this.parent_width = new_width;
            this.item_width =
                (new_width - this.col * this.item_gutter) / this.col;
        },
        current() {},
    },

    computed: {
        noMore() {
            return this.where.current >= this.pages;
        },
        disabled() {
            console.log(this.loading || this.noMore);
            return this.loading || this.noMore;
        },
    },
    components: {
        Waterfall,
    },
    data() {
        return {
            where: {
                current: 0,
                size: 0,
            },
            pages: 1,
            loading: false,
            parent_width: 1000,
            item_width: 220,
            item_gutter: 10,
            list: [
                {
                    isCreate: true,
                },
            ],
            col: 0,
            height: [1.1, 1.3, 1.5, 1.7],
        };
    },
    mounted() {
        this.parent_width = this.$refs.project_box.clientWidth - 10;
        window.addEventListener("resize", this.handleResize, true);
        this.setWaterfall();
    },
    methods: {
        moment,
        load() {
            (this.where.current += 1 <= this.pages) && this.getProje();
        },
        getProje() {
            this.loading = true;
            let where = this.where;
            getProje(where).then((res) => {
                this.pages = res.data.pages;
                res.data.records.map((item) => {
                    let index = Math.floor(Math.random() * this.height.length);
                    let height = this.height[index];
                    item.height = this.item_width * height;
                    this.list.push(item);
                });
                this.loading = false;
            });
        },
        setWaterfall(new_width) {
            let parent_width = new_width || this.parent_width;
            let item_gutter = 0;
            let col = 0;
            if (parent_width > 1900) {
                item_gutter = 20;
                col = 10;
            } else if (parent_width > 1400) {
                item_gutter = 14;
                col = 6;
            } else if (parent_width > 900) {
                item_gutter = 14;
                col = 6;
            } else {
                item_gutter = 10;
                col = 4;
            }
            this.item_gutter = item_gutter;
            this.col = col;
            !this.where.size && (this.where.size = col * 2);
        },
        handleResize() {
            // console.log(window.innerWidth);
            let obj_width = this.$refs.project_box.clientWidth;
            if (this.parent_width != obj_width) {
                // 父容器宽度发生变化
                this.parent_width = obj_width;
            }
        },
        delProje(uid) {
            this.$confirm("此操作将永久删除此工程, 是否继续操作?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: "创建中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.6)",
                    });
                    delProje(uid).then((res) => {
                        if (res.code == 200) {
                            this.$emit("removeItem", uid);

                            let index = this.list.findIndex(
                                (res) => res.id == uid
                            );
                            this.list.splice(index, 1);
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                        } else {
                            this.$message.error("删除失败!");
                        }
                    });
                    loading.close();
                })
                .catch(() => {
                    // this.$message.error( "删除失败!");
                });
        },
        reset_name(pid) {
            this.$prompt("请输入工程名字", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                // inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
                // inputErrorMessage: "文件命不能包含特殊字符跟空格",
            }).then(({ value }) => {
                const req = {
                    id: pid,
                    title: value,
                };
                updata_project(req).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        this.info.title = value;
                    }
                });
            });
        },
        openWork(projId) {
            this.$router.push(`/make/editing?projId=${projId}`);
        },
    },
    // 销毁监听
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>

<style lang="less" scoped>
.project_box {
    padding: 10px;
    height: 100%;
    overflow-y: auto;
}
.card {
    border-radius: 8px;
    overflow: hidden;
    line-height: 0;
    transition: all 0.2s linear;
    min-width: 190px;
    &:hover {
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.4);
        transform: translate(-6px, -6px);
    }
}
.create_box {
    background: linear-gradient(41deg, #bfe5ff 0%, #fae6ff 98%);
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.txt_h {
    color: #000;
    font-size: 16px;
}
.create_btn {
    width: 70px;
    height: 24px;
    line-height: 24px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: linear-gradient(135deg, #4d56ff 0%, #d000ff 100%);
    border-radius: 4px 4px 4px 4px;
    margin-top: 20px;
}
.project_item {
    background-color: #000;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s linear;
    position: relative;
    .project_info {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        line-height: 1.5;
        padding: 10px;
        box-sizing: border-box;
        z-index: 99;
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        transition: all 0.2s linear;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .btns {
            text-align: center;
            padding: 20px 0;
        }
    }
    &::before,
    &::after {
        content: "";
        display: block;
        width: 96px;
        height: 6px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        z-index: 9;
        transform: translateX(-50%);
    }
    &::before {
        top: 0;
        border-radius: 0 0 4px 4px;
    }
    &::after {
        bottom: 0;
        border-radius: 4px 4px 0 0;
    }
    &:hover {
        background-size: 130% auto;
        .project_info {
            transform: translateY(0);
        }
    }
}
.project_tis {
    text-align: center;
    padding: 10px;
}
</style>