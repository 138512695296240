var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.load),expression:"load"}],ref:"project_box",staticClass:"project_box",attrs:{"infinite-scroll-disabled":_vm.loading ||
        this.where.current >= this.pages ||
        (!_vm.autoLoad && this.where.current >= 1),"infinite-scroll-distance":0,"infinite-scroll-immediate":false}},[_c('Waterfall',{ref:"waterfall",attrs:{"list":_vm.list,"width":_vm.item_width,"gutter":_vm.item_gutter,"gridWidth":_vm.parent_width,"hasAroundGutter":false,"breakpoints":{
            1900: {
                //当屏幕宽度小于等于1900
                rowPerView: 7,
            },
            1440: {
                //当屏幕宽度小于等于1440
                rowPerView: 5,
            },
            900: {
                //当屏幕宽度小于等于800
                rowPerView: 4,
            },
        }},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(props.item.isCreate)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"create_box",style:({ height: _vm.item_width * 1.4 + 'px' })},[_c('div',{staticClass:"txt_box"},[_c('div',{staticClass:"txt_h"},[_vm._v("视频智能混剪")]),_c('div',[_vm._v("点击开始视频创作吧~")])]),_c('div',{staticClass:"create_btn"},[_vm._v("创建工程")])])]):_c('div',{staticClass:"card"},[_c('div',{staticClass:"project_item",style:({
                        height: props.item.height + 'px',
                        backgroundImage: ("url(" + (props.item.coverUrl) + ")"),
                    })},[_c('div',{staticClass:"project_info"},[_c('div',{staticClass:"btns"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.openWork(props.item.id)}}},[_vm._v("进入工程")]),_c('el-button',{attrs:{"type":"danger","size":"mini","plain":""},on:{"click":function($event){return _vm.delProje(props.item.id)}}},[_vm._v("删除工程")])],1),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title",on:{"dblclick":_vm.reset_name}},[_vm._v(" "+_vm._s(props.item.title)+" ")]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.moment(props.item.createTime).format( "YYYY-MM-DD HH:mm:ss" ))+" ")])])])])])]}}])}),_c('div',{staticClass:"project_tis"},[(this.where.current >= this.pages)?_c('div',[_c('i',{staticClass:"el-icon-warning"}),_vm._v(" 已经没有更多了 ")]):_vm._e(),(_vm.loading)?_c('div',[_c('i',{staticClass:"el-icon-loading"}),_vm._v(" 正在加载")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }